import React from 'react'
import Layout from '../components/layout'
import { graphql, useStaticQuery, Link } from 'gatsby'
//import { GatsbyImage } from "gatsby-plugin-image"
import Seo from '../components/seo'

const Espumas = () => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPost(
          filter: {
            categories: { nodes: { elemMatch: { name: { eq: "espumas" } } } }
          }
        ) {
          nodes {
            id
            title
            excerpt
            uri
            featuredImage {
              node {
                sourceUrl
                srcSet
                altText
              }
            }
          }
        }
      }
    `
  )

  const { allWpPost } = data

  return (
    <Layout>
      <Seo
        title='Espumas contra incendio Buckey - MECI'
        description='Contamos con varios agentes extinguidores para que pueda recargar sus equipos contra incendio. En existencia con diversos volumenes dependiendo de sus necesidades.'
      />
      <div className='d-flex flex-wrap px-4 my-5'>
        <div className='text-center mx-auto col-12 col-md-8'>
          <h1 className='fw-bold text-site-orange lh-1'>
            CONCENTRADOS DE ESPUMAS
          </h1>
          <p>
            Agentes antifuego de Buckeye con distintos agentes. Puede ponerse en
            contacto con nuestros especialistas que le asesorarán de la mejor
            manera.
          </p>
        </div>
        <div className='d-flex flex-wrap flex-column flex-md-row col-12 col-md-10 mx-auto align-items-center justify-content-between'>
          {allWpPost.nodes.map(({ id, title, excerpt, featuredImage, uri }) => (
            <div
              key={id}
              className='d-flex flex-wrap justify-content-start align-items-center col-12 col-md-6 border border-grey-light rounded bg-white'
            >
              <div className='col-12 col-md-4'>
                <img
                  className='p-3 img-fluid'
                  src={featuredImage.node.sourceUrl}
                  srcSet={featuredImage.node.srcSet}
                  alt={featuredImage.node.altText}
                />
              </div>
              <div className='col-12 col-md-8 ps-md-1 pe-md-3 px-3'>
                <p className='card-title text-uppercase text-site-orange fw-bold lead fs-5 m-0 lh-1'>
                  {title}
                </p>
                <p className='ellipsis'>
                  {excerpt.replace(/(<([^>]+)>)/gi, ' ')}
                </p>
                <div className='d-grid gap-2'>
                  <Link
                    className='btn btn-orange fs-6 mb-2 mb-md-0'
                    type='button'
                    to={uri}
                  >
                    Ver detalles
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Espumas
